// TODO: Address this combine reducers error
// @ts-ignore
import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../pages/Login/authSlice";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import appSlice from "./appSlice";

const rootReducer = combineReducers({ auth: authSlice, app: appSlice });

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
