import { KeyIcon } from "@heroicons/react/solid";
import React from "react";
import { DEFAULT_PROFILE_PICTURE_URL } from "../config/images";

export default function AttachedHeader({
  title,
  primaryAction,
  primaryActionText,
  secondaryAction,
  secondaryActionText,
  IconComponent,
  PrimaryActionComponent,
  SecondaryActionComponent,
}: {
  title: string;
  primaryAction?: any;
  primaryActionText?: string;
  secondaryAction?: any;
  secondaryActionText?: string;
  IconComponent: any;
  PrimaryActionComponent?: any;
  SecondaryActionComponent?: any;
}) {
  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            {/* Profile */}
            <div className="flex items-center">
              <IconComponent className="hidden w-16 h-16 rounded-full sm:block" />
              <div>
                <div className="flex items-center">
                  <IconComponent className="w-16 h-16 rounded-full sm:hidden" />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {title}
                  </h1>
                </div>
                {/* <dl className="flex flex-col mt-6 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-sm font-medium text-gray-500 capitalize sm:mr-6">
                    <OfficeBuildingIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Catalyst
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="flex items-center mt-3 text-sm font-medium text-gray-500 capitalize sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                    Pro Account
                  </dd>
                </dl> */}
              </div>
            </div>
          </div>
          <div className="flex mt-6 space-x-3 md:mt-0 md:ml-4">
            {secondaryAction && (
              <button
                type="button"
                onClick={secondaryAction}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              >
                {secondaryActionText || "Secondary Action"}
              </button>
            )}
            {SecondaryActionComponent && <SecondaryActionComponent />}

            {primaryAction && (
              <button
                type="button"
                onClick={primaryAction}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              >
                {primaryActionText || "Primary Action"}
              </button>
            )}
            {PrimaryActionComponent && <PrimaryActionComponent />}
          </div>
        </div>
      </div>
    </div>
  );
}
