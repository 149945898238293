import { Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Logo } from "./Logo";
import loadingAnimation from "../images/lotties/loader.json";

export default function FullScreenLoader() {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };

  const [unexpectedlyLong, setUnexpectedlyLong] = useState(false);

  useEffect(() => {
    setTimeout(() => setUnexpectedlyLong(true), 3500);
  }, []);

  if (!unexpectedlyLong)
    return (
      <>
        <div className="flex items-center justify-center min-h-screen min-w-screen bg-cyan-100">
          <div className="flex flex-col items-center justify-center w-100">
            <Lottie options={lottieOptions} height={500} width={500} />
            <p className="font-bold">Loading...</p>
          </div>
        </div>
      </>
    );

  return (
    <div>
      <div className="relative flex items-center min-h-screen p-5 overflow-hidden min-w-screen bg-cyan-100 lg:p-20">
        <div className="relative items-center flex-1 min-w-full min-h-full p-10 text-center text-gray-800 bg-white shadow-xl rounded-3xl lg:p-20 md:flex md:text-left">
          <div className="w-full md:w-1/2">
            <div className="mb-10 lg:mb-20">
              <Logo />
            </div>
            <div className="mb-10 font-light text-gray-600 md:mb-20">
              <h1 className="mb-10 text-3xl font-black lg:text-5xl text-cyan-500">
                <Spinner size="xl" mr="4" />
                Loading...
              </h1>
              <p className="font-bold">
                We're currently fetching your account information...
              </p>
              <p>
                Please wait. If you are encountering any issues, please contact
                us using the button below.
              </p>
            </div>
            <div className="mb-20 md:mb-0">
              <a
                href="mailto: admin@catalyst.chat"
                className="inline-block p-1 rounded-lg bg-gradient-to-r from-pink-500 via-cyan-500 to-yellow-500"
              >
                <span className="block px-5 py-3 font-medium text-white bg-gray-900 rounded-md hover:bg-opacity-75">
                  Contact Us
                </span>
              </a>
            </div>
          </div>
          <div className="w-full text-center md:w-1/2">
            <Lottie options={lottieOptions} height={500} width={500} />
          </div>
        </div>
        <div className="absolute w-64 transform -rotate-45 rounded-full pointer-events-none md:w-96 h-96 md:h-full bg-cyan-200 bg-opacity-30 -top-64 md:-top-96 right-20 md:right-32" />
        <div className="absolute h-full transform -rotate-45 rounded-full pointer-events-none w-96 bg-cyan-200 bg-opacity-20 -bottom-96 right-64" />
      </div>
    </div>
  );
}
