import React, { useState, FormEvent, useEffect } from "react";
import { FaGoogle, FaFacebook, FaGithub } from "react-icons/fa";
import { useHistory, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../App/rootReducer";
import { auth } from "../../App/AppAuthentication";
import { IonPage } from "@ionic/react";
import { Logo } from "../../components/Logo";
import historyInstance from "../../utils/history";
import FirebaseUIWrapper from "../../components/FirebaseUIWrapper";
import firebase from "firebase";

const Login = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const authState = useSelector((state: RootState) => state?.auth);

  const handleEmailAndPasswordLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(emailAddress, password)
      .catch((error: any) => {
        // Handle Errors here.
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    setLoading(false);
  };

  useEffect(() => {
    if (authState.status === "in" && authState.retrievedProfile)
      historyInstance.push("/home");
    if (authState.status === "in")
      toast("Signed in... Waiting for profile data...", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    // eslint-disable-next-line
  }, [authState.status, authState.retrievedProfile]);

  return (
    <IonPage>
      <div className="flex min-h-screen bg-white">
        <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="w-full max-w-sm mx-auto lg:w-96">
            <div>
              <Logo />
              <h2 className="mt-6 text-3xl font-extrabold leading-9 text-gray-900">
                Chat With Catalyst
              </h2>
               <p className="mt-2 text-sm leading-5 text-gray-600 max-w">Set up and manage your instance</p>
              {/* <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
                Or{" "}
                <Link
                  to="/students/sign-up"
                  className="font-medium transition duration-150 ease-in-out text-cyan-600 hover:text-cyan-500 focus:outline-none focus:underline"
                >
                  get started with Catalyst
                </Link>
              </p> */}
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm font-medium leading-5 text-gray-700">
                    Sign in (or up) with
                  </p>

                  <div className="grid grid-cols-2 gap-3 mt-1">
                    <div>
                      <span className="inline-flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          onClick={() => {
                            const provider =
                              new firebase.auth.GoogleAuthProvider();
                            provider.addScope("profile");

                            firebase
                              .auth()
                              .signInWithPopup(provider)
                              .then(({ user }) => toast.success("Signed in"))
                              .catch((error: any) =>
                                toast.error(JSON.stringify(error))
                              );
                          }}
                          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-400 focus:outline-none focus:border-cyan-300 focus:shadow-outline-cyan"
                          aria-label="Sign in with Google (GSuite)"
                        >
                          <FaGoogle className="w-5 h-5" />
                        </button>
                      </span>
                    </div>

                    <div>
                      <span className="inline-flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-400 focus:outline-none focus:border-cyan-300 focus:shadow-outline-cyan"
                          aria-label="Sign in with GitHub"
                          onClick={() => {
                            const provider =
                              new firebase.auth.GithubAuthProvider();
                            provider.addScope("profile");

                            firebase
                              .auth()
                              .signInWithPopup(provider)
                              .then(({ user }) => toast.success("Signed in"))
                              .catch((error: any) =>
                                toast.error(JSON.stringify(error))
                              );
                          }}
                        >
                          <FaGithub className="w-5 h-5" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm leading-5">
                    <span className="px-2 text-gray-500 bg-white">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form
                  onSubmit={handleEmailAndPasswordLogin}
                  className="space-y-6"
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        type="email"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-cyan focus:border-cyan-300 sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-cyan focus:border-cyan-300 sm:text-sm sm:leading-5"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember_me"
                        type="checkbox"
                        className="w-4 h-4 transition duration-150 ease-in-out form-checkbox text-cyan-600"
                      />
                      <label
                        htmlFor="remember_me"
                        className="block ml-2 text-sm leading-5 text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    {/* <div className="text-sm leading-5">
                      <a
                        href="/"
                        className="font-medium transition duration-150 ease-in-out text-cyan-600 hover:text-cyan-500 focus:outline-none focus:underline"
                      >
                        Forgot your password?
                      </a>
                    </div> */}
                  </div>

                  {/* <FirebaseUIWrapper /> */}

                  {loading && <SigningInStatus />}
                  {!loading && (
                    <div>
                      <span className="block w-full rounded-md shadow-sm">
                        <button
                          type="submit"
                          className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:border-cyan-700 focus:shadow-outline-cyan active:bg-cyan-700"
                        >
                          Sign in
                        </button>
                      </span>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 hidden w-0 lg:block">
          {/* <img
            className="absolute inset-0 object-cover w-full h-full"
            src="https://wp.stanforddaily.com/wp-content/uploads/2020/08/IMG_8492-1-scaled.jpeg"
            alt="School Background"
          /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="1440"
            height="1000"
            preserveAspectRatio="none"
            viewBox="0 0 1440 1000"
          >
            <g mask='url("#SvgjsMask1043")' fill="none">
              <path
                d="M361.92 325.53L374.1 325.53L374.1 402.41L361.92 402.41z"
                fill="rgba(196, 113, 237, 1)"
              ></path>
              <path
                d="M142.79 286.24L234.98 286.24L234.98 323.58L142.79 323.58z"
                fill="rgba(246, 79, 89, 1)"
              ></path>
              <path
                d="M262.83 445.58 a43.06 43.06 0 1 0 86.12 0 a43.06 43.06 0 1 0 -86.12 0z"
                stroke="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M246.82 790.18L292.01 790.18L292.01 861.61L246.82 861.61z"
                fill="rgba(196, 113, 237, 1)"
              ></path>
              <path
                d="M647.84 691.99 a28.65 28.65 0 1 0 57.3 0 a28.65 28.65 0 1 0 -57.3 0z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M1406.12 294.55a5.15 5.15 0 1 0 10.3 0.24z"
                fill="rgba(196, 113, 237, 1)"
              ></path>
              <path
                d="M1273.05 795.01L1302.94 795.01L1302.94 804.45L1273.05 804.45z"
                stroke="rgba(196, 113, 237, 1)"
              ></path>
              <path
                d="M65.16 168.66 a74.85 74.85 0 1 0 149.7 0 a74.85 74.85 0 1 0 -149.7 0z"
                fill="rgba(246, 79, 89, 1)"
              ></path>
              <path
                d="M899.79 246.96a89.03 89.03 0 1 0 164.12 69.08z"
                fill="rgba(196, 113, 237, 1)"
              ></path>
              <path
                d="M365.98 566.5L412.93 566.5L412.93 585.83L365.98 585.83z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M160.82 772.3L257.07 772.3L257.07 868.55L160.82 868.55z"
                fill="rgba(246, 79, 89, 1)"
              ></path>
              <path
                d="M349.16 43.24 a96.43 96.43 0 1 0 192.86 0 a96.43 96.43 0 1 0 -192.86 0z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M162.76 620.24 a91.97 91.97 0 1 0 183.94 0 a91.97 91.97 0 1 0 -183.94 0z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M831.06 705.14a23.18 23.18 0 1 0-44.91 11.53z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M64.83 306.23L149.75 306.23L149.75 391.15L64.83 391.15z"
                fill="rgba(246, 79, 89, 1)"
              ></path>
              <path
                d="M448 415.56L533.6 415.56L533.6 501.16L448 501.16z"
                fill="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M1278.47 163L1299.59 163L1299.59 171.99L1278.47 171.99z"
                stroke="rgba(18, 194, 233, 1)"
              ></path>
              <path
                d="M820.67 492.66 a22.25 22.25 0 1 0 44.5 0 a22.25 22.25 0 1 0 -44.5 0z"
                fill="rgba(196, 113, 237, 1)"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1043">
                <rect width="1440" height="1000" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
    </IonPage>
  );
};

const SigningInStatus = () => (
  <div className="p-4 rounded-md bg-cyan-50">
    <div className="flex">
      <div className="flex-shrink-0">
        {/* Heroicon name: information-circle */}
        <svg
          className="w-5 h-5 text-cyan-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="flex-1 ml-3 md:flex md:justify-between">
        <p className="text-sm font-bold leading-5 text-cyan-700">
          Securely signing you in...
        </p>
      </div>
    </div>
  </div>
);

export default Login;
