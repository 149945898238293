import { Disclosure, Transition, Menu } from "@headlessui/react";
import { SearchIcon, XIcon, BellIcon, MenuIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";
import { useTypedSelector } from "../App/rootReducer";
import { Logo } from "./Logo";
import { NavLink } from "react-router-dom";
import { getCoreScreens } from "../config/navConfig";
import { auth } from "../App/AppAuthentication";
import { DEFAULT_PROFILE_PICTURE_URL } from "../config/images";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function ColoredHeader() {
  const { displayName, role, profilePictureUrl } = useTypedSelector(
    (state) => state.auth.profile
  );

  const profile = [{ title: "Sign Out", action: () => auth.signOut() }];

  return (
    <Disclosure
      as="nav"
      className="bg-red-600 border-b border-red-300 border-opacity-25 lg:border-none"
    >
      {({ open }) => (
        <>
          <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16 lg:border-b lg:border-red-400 lg:border-opacity-25">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <Logo className="block w-8 h-8" />
                </div>
                <div className="hidden lg:block lg:ml-10">
                  <div className="flex space-x-4">
                    {getCoreScreens(role).map((navItem) => (
                      <NavLink
                        activeClassName="bg-red-700 text-white rounded-md py-2 px-3 text-sm font-medium"
                        key={navItem.path}
                        to={navItem.path}
                        className="px-3 py-2 text-sm font-medium text-white rounded-md hover:bg-red-500 hover:bg-opacity-75"
                      >
                        {navItem.title}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-center flex-1 px-2 lg:ml-6 lg:justify-end">
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SearchIcon className="w-5 h-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      className="block w-full py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white focus:border-white sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 bg-red-600 rounded-md text-rose-200 hover:text-white hover:bg-red-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:block lg:ml-4">
                <div className="flex items-center">
                  <button className="flex-shrink-0 p-1 bg-red-600 rounded-full text-rose-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="w-6 h-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0 ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="flex text-sm text-white bg-red-600 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="w-8 h-8 rounded-full"
                              src={profilePictureUrl || ""}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {profile.map((item) => (
                              <Menu.Item key={item.title}>
                                {({ active }) => (
                                  <button
                                    onClick={item.action}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-sm text-gray-700 w-full"
                                    )}
                                  >
                                    {item.title}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {getCoreScreens(role).map((navItem) => (
                <NavLink
                  activeClassName="bg-red-700 text-white block rounded-md py-2 px-3 text-base font-medium"
                  key={navItem.path}
                  to={navItem.path}
                  className="block px-3 py-2 text-base font-medium text-white rounded-md hover:bg-red-500 hover:bg-opacity-75"
                >
                  {navItem.title}
                </NavLink>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-red-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={DEFAULT_PROFILE_PICTURE_URL}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {displayName}
                  </div>
                  <div className="text-sm font-medium text-rose-300">
                    {role}
                  </div>
                </div>
                <button className="flex-shrink-0 p-1 ml-auto bg-red-600 rounded-full text-rose-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="px-2 mt-3 space-y-1">
                {profile.map((item) => (
                  <button
                    key={item.title}
                    onClick={item.action}
                    className="block w-full px-3 py-2 text-base font-medium text-white rounded-md hover:bg-red-500 hover:bg-opacity-75"
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
