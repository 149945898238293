import { CurrencyDollarIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import AttachedHeader from "../../components/AttachedHeader";
import MainLayout from "../../components/MainLayout";
import RoomTable from "../../components/RoomTable";
import axiosInstance from "../../utils/axiosInstance";
import AdminRoomTable from "./AdminRoomTable";

export default function AdminRooms() {
  const [roomData, setRoomData] = useState<any>();

  useEffect(() => {
    axiosInstance
      .get(`/livekit/rooms/list`)
      .then((res) => {
        console.log(res.data);
        setRoomData(res.data);
      })
      .catch((e) => {
        toast.error("There was an issue retrieving the live room data.");
        console.log(e);
      });
  }, []);

  return (
    <MainLayout>
      <AttachedHeader
        IconComponent={CurrencyDollarIcon}
        title="Open Rooms"
        // SecondaryActionComponent={
        //   isProAccount ? SwitchToFreeAccountButton : SwitchToProAccountButton
        // }
        // primaryAction={handleViewStripeCustomerDashboard}
        // primaryActionText="View Customer Dashboard"
      />

      <div className="mt-8">
        <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="bg-gray-100">
            <div className="pb-16 mt-8 sm:mt-12 sm:pb-20 lg:pb-28">
              <div className="relative">
                <div className="absolute inset-0 bg-gray-100 h-1/2" />
                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <AdminRoomTable roomData={roomData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
