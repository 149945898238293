import { IonPage } from "@ionic/react";
import React from "react";
import ColoredHeader from "./ColoredHeader";

export default function OverlapLayout({
  title = "Default Title",
  children,
}: any) {
  return (
    <IonPage>
      <div className="min-h-screen bg-gray-100 max-h-screen overflow-y-scroll">
        <div className="bg-red-600 pb-32">
          <ColoredHeader />
          <header className="py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-white">{title}</h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            {children}
            {/* /End replace */}
          </div>
        </main>
      </div>
    </IonPage>
  );
}
