import React from "react";
import toast from "react-hot-toast";
import AttachedHeader from "../../components/AttachedHeader";
import MainLayout from "../../components/MainLayout";
import {
  ClockIcon,
  HomeIcon,
  KeyIcon,
  LinkIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";

import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";

import CatalystChat from "catalyst-vc-react";
import { useTypedSelector } from "../../App/rootReducer";

const scope = { CatalystChat };

export default function APIKeys() {
  const firebaseUid = useTypedSelector((state) => state.auth.firebaseUid);
  const [domainInputValue, setDomainInputValue] = React.useState("");
  const [domains, setDomains] = React.useState<string[]>([]);

  const CODE_SAMPLE = `
  <CatalystChat
    room="EXAMPLE"
    appId="${firebaseUid}"
    name="MEMBER_NAME"
    disableChat
    disableSetupView
	disableRefreshBtn
    onEndCall={() => alert("Call ended!")}
  />
`;
  return (
    <MainLayout>
      <AttachedHeader
        IconComponent={KeyIcon}
        title="API Keys & Implementation"
        primaryAction={() =>
          toast.error(
            "Multiple API keys are not yet supported for Google accounts."
          )
        }
        primaryActionText="Add API Key"
      />

      <div className="mt-8">
        <div className="max-w-6xl px-4 pb-24 mx-auto sm:px-6 lg:px-8">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Client Implementation
          </h2>
          <h5 className="text-sm leading-6 text-gray-600 font-small">
            No server-side token generation required
          </h5>

          <h5 className="mt-4 mb-4 text-sm leading-6 text-gray-600 font-small">
            Your App ID: <strong>{firebaseUid}</strong>
          </h5>
          <div>
            <LiveProvider code={CODE_SAMPLE} scope={scope}>
              <div className="flex overflow-hidden bg-white divide-x divide-gray-200 rounded-lg shadow">
                <div className="px-4 pt-5 pb-24 sm:px-1">
                  {/* Content goes here */}
                  {/* We use less vertical padding on card headers on desktop than on body sections */}
                  <div>
                    <LiveEditor />
                  </div>

                  <LiveError />
                </div>
                <div className="flex-1 px-4 py-5 rounded-xl sm:p-6">
                  <LivePreview
                    style={{
                      flex: 1,
                      width: "100%",
                      height: "350px",
                      borderRadius: "50px",
                    }}
                  />
                </div>
              </div>
            </LiveProvider>
          </div>

          {/* <button className="flex items-center justify-between px-5 py-3 mt-4 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-600 group">
            <span className="text-lg font-medium group-hover:text-white">
              Create Another Test Client
            </span>

            <span className="flex-shrink-0 p-2 ml-4 bg-white border border-blue-600 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>
          </button> */}
          <div className="mt-8">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Security & Authorized Domains
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Customize your security settings to prevent unwanted usage
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                      <fieldset>
                        <div>
                          <legend className="text-base font-medium text-gray-900">
                            Domain Restrictions (WIP)
                          </legend>
                          <p className="text-sm text-gray-500">
                            Choose how Catalyst should restrict user joins
                          </p>
                        </div>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              id="push-everything"
                              name="push-notifications"
                              type="radio"
                              className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="push-everything"
                              className="block ml-3 text-sm font-medium text-gray-700"
                            >
                              Allow All
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push-email"
                              name="push-notifications"
                              type="radio"
                              className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="push-email"
                              className="block ml-3 text-sm font-medium text-gray-700"
                            >
                              Only allow authorized domains (listed below)
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push-nothing"
                              name="push-notifications"
                              type="radio"
                              className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="push-nothing"
                              className="block ml-3 text-sm font-medium text-gray-700"
                            >
                              Only allow server-side client joins with token
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Authorized Domains
                          </label>
                          <div className="relative flex items-center mt-1">
                            <input
                              type="text"
                              value={domainInputValue}
                              onChange={(e) => {
                                setDomainInputValue(e.target.value);
                              }}
                              className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                              <button
                                type="button"
                                onClick={() => {
                                  setDomains((domains) => [
                                    ...domains,
                                    domainInputValue,
                                  ]);
                                }}
                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ul className="divide-y divide-gray-200">
                        {domains.map((domain) => (
                          <li key={domain} className="flex py-4">
                            <LinkIcon className="w-10 h-10 rounded-full" />
                            <div className="ml-3">
                              <p className="text-sm font-medium text-gray-900">
                                {domain}
                              </p>
                              <p className="text-sm text-gray-500">{domain}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <h2 className="max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
          Recent activity
        </h2> */}
      </div>
    </MainLayout>
  );
}
