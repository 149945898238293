import React from "react";
import toast from "react-hot-toast";
import AttachedHeader from "../../components/AttachedHeader";
import MainLayout from "../../components/MainLayout";
import {
  CheckCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon,
  KeyIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Modal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useTypedSelector } from "../../App/rootReducer";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import SwitchToFreeAccountButton from "./SwitchToFreeAccountButton";
import SwitchToProAccountButton from "./SwitchToProAccountButton";
import axiosInstance from "../../utils/axiosInstance";

const includedFeatures = [
  "Unlimited simultaneous rooms",
  "Unlimited room participants",
  "Custom theming",
  "Background removal",
  "Screen recording",
  "Whiteboarding",
  "Breakout rooms",
  "API access",
];

function ProCard() {
  return (
    <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
      <div className="flex-1 px-6 py-8 bg-white lg:p-12">
        <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
          Catalyst Pro
        </h3>
        <p className="mt-6 text-base text-gray-500">
          You're on our infinitely-scalable pro plan. We'll send an invoice to
          your email at the end of each month.
        </p>
        <div className="mt-8">
          <div className="flex items-center">
            <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-light-blue-600">
              What's included
            </h4>
            <div className="flex-1 border-t-2 border-gray-200" />
          </div>
          <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
            {includedFeatures.map((feature) => (
              <li key={feature} className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="w-5 h-5 text-purple-400"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-3 text-sm text-gray-700">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
        <div className="flex items-center justify-center text-5xl font-extrabold text-gray-900">
          <span>$79.99</span>
          <span className="ml-3 text-xl font-medium text-gray-500">USD</span>
        </div>
        <p className="mt-4 text-lg font-medium leading-6 text-gray-900 ">
          Per 300 Monthly Active Users
        </p>
        <p className="mt-4 text-sm">
          <Popover>
            <PopoverTrigger>
              <button className="font-medium text-gray-500 underline">
                What counts as a monthly active user?
              </button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Monthly Active User</PopoverHeader>
              <PopoverBody>
                A monthly active user (MAU) is measured as a unique device
                joining a Catalyst call with at least one other person. We
                utilize cookies to ensure that you are only charged once per
                unique device.
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </p>
        <div className="mt-6">
          <div className="rounded-md shadow">
            <a
              href="mailto:admin@catalyst.chat"
              className="flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
            >
              Contact Us
            </a>
          </div>
        </div>
        {/* <div className="mt-4 text-sm">
    <a href="#" className="font-medium text-gray-900">
      Get a free sample{" "}
      <span className="font-normal text-gray-500">
        (20MB)
      </span>
    </a>
  </div> */}
      </div>
    </div>
  );
}

function FreeCard() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AddPaymentMethodModal isOpen={isOpen} onClose={onClose} />
      <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
        <div className="flex-1 px-6 py-8 bg-white lg:p-12">
          <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
            Catalyst Dev
          </h3>
          <p className="mt-6 text-base text-gray-500">
            You're on our capable free tier. Use Catalyst for up to 30 monthly active users at no cost.
          </p>
          <div className="mt-8">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-light-blue-600">
                Upgrade For
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex items-start lg:col-span-1">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-5 h-5 text-purple-400"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-sm text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="px-6 py-8 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <div className="flex items-center justify-center text-5xl font-extrabold text-gray-900">
            <span>$0.00</span>
            <span className="ml-3 text-xl font-medium text-gray-500">USD</span>
          </div>
          <p className="mt-4 text-lg font-medium leading-6 text-gray-900 ">
            Up To 30 Monthly Active Users
          </p>
          <p className="mt-4 text-sm">
            <Popover>
              <PopoverTrigger>
                <button className="font-medium text-gray-500 underline">
                  What counts as a monthly active user?
                </button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Monthly Active User</PopoverHeader>
                <PopoverBody>
                  A monthly active user (MAU) is measured as a unique device
                  joining a Catalyst call with at least one other person. We
                  utilize cookies to ensure that you are only charged once per
                  unique device.
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </p>
          <div className="mt-6">
            <div className="rounded-md shadow">
              <button
                onClick={onOpen}
                className="flex items-center w-full justify-center px-5 py-3 text-base font-medium text-white bg-gray-800 border border-transparent rounded-md hover:bg-gray-900"
              >
                Upgrade to Catalyst Pro
              </button>
            </div>
          </div>
          {/* <div className="mt-4 text-sm">
      <a href="#" className="font-medium text-gray-900">
        Get a free sample{" "}
        <span className="font-normal text-gray-500">
          (20MB)
        </span>
      </a>
    </div> */}
        </div>
      </div>
    </>
  );
}

export default function Plans() {
  const { isProAccount } = useTypedSelector((state) => state.auth.profile);

  const handleViewStripeCustomerDashboard = () => {
    console.log("help");
    axiosInstance
      .post("/stripe/customer/dashboard")
      .then((res) => {
        console.log(res);
        window.location.assign(res?.data?.url);
      })
      .catch((e) => {
        toast.error(JSON.stringify(e));
        console.error(e?.response);
        toast.error(JSON.stringify(e?.response?.message));
      });
  };

  return (
    <>
      <MainLayout>
        <AttachedHeader
          IconComponent={CurrencyDollarIcon}
          title="Your Plan"
          SecondaryActionComponent={
            isProAccount ? SwitchToFreeAccountButton : SwitchToProAccountButton
          }
          primaryAction={handleViewStripeCustomerDashboard}
          primaryActionText="View Customer Dashboard"
        />

        <div className="mt-8">
          <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="bg-gray-100">
              <div className="pb-16 mt-8 sm:mt-12 sm:pb-20 lg:pb-28">
                <div className="relative">
                  <div className="absolute inset-0 bg-gray-100 h-1/2" />
                  <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    {isProAccount ? <ProCard /> : <FreeCard />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
