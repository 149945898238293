export const productionFirebase = {
  apiKey: "AIzaSyCeHlnjCpmGQOjPXmvDRGYXBfxld0j2o-w",
  authDomain: "catalyst-manage.firebaseapp.com",
  projectId: "catalyst-manage",
  storageBucket: "catalyst-manage.appspot.com",
  messagingSenderId: "872356163264",
  appId: "1:872356163264:web:a4db12f35478343a930725",
  measurementId: "G-J6WE7L2KQE",
};
export const developmentFirebase = {
  apiKey: "AIzaSyAMdEsTmDfaokxUDVLfY2vja9Q1Uy2yH6w",
  authDomain: "catalyst-manage-dev.firebaseapp.com",
  projectId: "catalyst-manage-dev",
  storageBucket: "catalyst-manage-dev.appspot.com",
  messagingSenderId: "138819407379",
  appId: "1:138819407379:web:6e06e08c90bbfa1f3f39e6",
  measurementId: "G-1S5F8VWT8C",
};
