import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  appInitialized: boolean;
}

const initialState: AppState = {
  appInitialized: false,
};

export const setAppInitialized = createAction<boolean>(
  "appState/setAppInitialized"
);

const appSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      setAppInitialized,
      (state: AppState, action: PayloadAction<boolean>) => {
        state.appInitialized = action.payload;
      }
    );
  },
});

export default appSlice.reducer;
