import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import axiosInstance from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setProStatus } from "../Login/authSlice";

export default function SwitchToFreeAccountButton() {
  const dispatch = useDispatch();

  const handleSwitchToFreeAccount = async () => {
    try {
      await axiosInstance.put("/users/me/switch-to-free");
      toast.success("Successfully switched to a free account.");
      dispatch(setProStatus(false));
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong while trying to switch to a free account. Please try again."
      );
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <button
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
        onClick={onOpen}
      >
        Switch to Free Account
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Switch to a free account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to switch to a free account? You will still be
            billed for your usage thus far and will not be able to go over 30
            monthly active users. If you have already done so, you will no
            longer be able to add any users to your application.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSwitchToFreeAccount}
            >
              Switch to Free
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
