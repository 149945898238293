import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// export interface profileSchool {
//   id: string | null;
//   name: string | null;
//   // role: string | null;
// }

export interface CoreAuthenticationState {
  status: string | null;
  token: string | null;
  firebaseUid: string | null;
}

export interface AuthenticationState extends CoreAuthenticationState {
  retrievedProfile: boolean;
  profile: AuthenticationProfile;
}

export interface AuthenticationProfile {
  isProAccount: boolean | null;
  databaseUid: string | null;
  displayName: string | null;
  role: string | null;
  registrationStep: string | null;
  profilePictureUrl: string | null;
  email: string | null;
}

const initialState: AuthenticationState = {
  profile: {
    isProAccount: null,
    databaseUid: null,
    displayName: null,
    role: null,
    registrationStep: null,
    profilePictureUrl: null,
    email: null,
  },
  status: "loading", // Either "loading" or "in"
  retrievedProfile: false,
  token: null,
  firebaseUid: null,
};

export const signedIn = createAction<CoreAuthenticationState>("auth/signedIn");
export const setProfileData =
  createAction<AuthenticationProfile>("auth/profileData");
export const updateToken = createAction<string>("auth/updateToken");
export const signedOut = createAction("auth/signedOut");
export const setProStatus = createAction<boolean>(
  "auth/profileData/setProStatus"
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      signedIn,
      (
        state: AuthenticationState,
        action: PayloadAction<CoreAuthenticationState>
      ) => {
        state.status = action.payload.status;
        state.token = action.payload.token;
        state.firebaseUid = action.payload.firebaseUid;
      }
    );

    builder.addCase(
      updateToken,
      (state: AuthenticationState, action: PayloadAction<string>) => {
        state.token = action.payload;
      }
    );

    builder.addCase(
      setProfileData,
      (
        state: AuthenticationState,
        action: PayloadAction<AuthenticationProfile>
      ) => {
        state.retrievedProfile = true;
        state.profile = action.payload;
      }
    );

    builder.addCase(
      signedOut,
      (state: AuthenticationState, action: PayloadAction) => {
        // Sets state to initial state
        Object.assign(state, initialState);
      }
    );

    builder.addCase(
      setProStatus,
      (state: AuthenticationState, action: PayloadAction<boolean>) => {
        state.profile.isProAccount = action.payload;
      }
    );
  },
});

export default authSlice.reducer;
