import Home from "../pages/Home/index";

import { home } from "ionicons/icons";
import Webhooks from "../pages/Webhooks/index";
import APIKeys from "../pages/APIKeys/index";
import Plans from "../pages/Plans/index";
import AdminRooms from "../pages/AdminRooms/index";

import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  KeyIcon,
  GlobeIcon,
} from "@heroicons/react/outline";
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClipboardListIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from "@heroicons/react/solid";

// "all" MEANS THAT ALL ROLES CAN VIEW IT

export const navConfig = [
  {
    title: "Home",
    roles: "all",
    path: "/home",
    icon: HomeIcon,
    component: Home,
  },
  {
    title: "API Keys",
    roles: "all",
    path: "/api-keys",
    icon: KeyIcon,
    component: APIKeys,
  },
  {
    title: "Plans",
    roles: "all",
    path: "/plans",
    icon: CreditCardIcon,
    component: Plans,
  },
  {
    title: "Webhooks",
    roles: "all",
    path: "/webhooks",
    icon: GlobeIcon,
    component: Webhooks,
  },
  {
    title: "Rooms",
    roles: ["admin"],
    path: "/admin/rooms",
    icon: ClipboardListIcon,
    component: AdminRooms,
  },
  // {
  //   title: "Configure Rooms",
  //   path: "/rooms/configure",
  //   roles: "all",
  //   icon: UserGroupIcon,
  //   component: Home,
  // },
  // {
  //   title: "Analytics",
  //   path: "/analytics",
  //   roles: "all",
  //   icon: DocumentReportIcon,
  //   current: false,
  //   component: Home,
  // },
];

export const secondaryNavigation = [
  { title: "Settings", path: "/settings", icon: CogIcon },
];

export const getCoreScreens = (role: any) =>
  navConfig.filter(
    (screen) => screen.roles.includes(role) || screen.roles === "all"
  );

export default navConfig;
