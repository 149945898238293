import React from "react";
import { GlobeIcon } from "@heroicons/react/solid";

import { useTypedSelector } from "../../App/rootReducer";
import { classNames } from "../../utils/tailwind";
import ParticipantModalButton from "./ParticipantModalButton";

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

export default function RoomTable({ roomData }: any) {
  const firebaseUid = useTypedSelector((state) => state.auth.firebaseUid);

  const handleCloseRoom = (roomName: string) => {
    console.log("closing room", roomName);
  };

  return (
    <div className="block">
      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex flex-col mt-2">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Session
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                    SID
                  </th>
                  <th className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50 md:block">
                    Creation Time
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                    Empty Timeout
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                    Room Actions{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {roomData?.length === 0 && (
                  <tr className="bg-white">
                    <td className="w-full px-6 py-4 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                      <h1>No rooms active.</h1>
                    </td>
                  </tr>
                )}
                {roomData?.map((room: any) => {
                  return (
                    <tr key={room.sid} className="bg-white">
                      <td className="w-full px-6 py-4 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        <div className="flex">
                          <button
                            onClick={() => alert(room?.name)}
                            className="inline-flex space-x-2 text-sm truncate group"
                          >
                            <GlobeIcon
                              className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <p className="text-gray-500 truncate group-hover:text-gray-900">
                              {/* If firebaseUid in room name, indicate that this is a room created by this account */}
                              {room?.name}{" "}
                              {room?.name.includes(firebaseUid) && (
                                <span
                                  className={classNames(
                                    statusStyles["success"],
                                    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                                  )}
                                >
                                  This Account
                                </span>
                              )}
                            </p>
                          </button>
                        </div>
                      </td>
                      <td className="hidden px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:block">
                        <span
                          className={classNames(
                            statusStyles["success"],
                            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                          )}
                        >
                          {room?.sid}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        {/* <span className="font-medium text-gray-900">
															{room.maxUsers}{" "}
														</span> */}
                        {room?.creationTime}
                      </td>
                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        {room?.emptyTimeout}
                      </td>
                      {/* <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
														<time dateTime={room.creationDate}>
															{room.creationDate}
														</time>
													</td> */}

                      <td className="px-6 py-4 text-sm text-right text-gray-500 whitespace-nowrap">
                        {/* <button
                          type="button"
                          onClick={() => handleCloseRoom(room?.name)}
                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Close Room
                        </button> */}
                        <ParticipantModalButton roomName={room?.name} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* Pagination */}
            {/* <nav
                  className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">1</span> to{" "}
                      <span className="font-medium">10</span> of{" "}
                      <span className="font-medium">20</span> results
                    </p>
                  </div>
                  <div className="flex justify-between flex-1 sm:justify-end">
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      Previous
                    </a>
                    <a
                      href="#"
                      className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      Next
                    </a>
                  </div>
                </nav> */}
          </div>
        </div>
      </div>
      {JSON.stringify(roomData)}
    </div>
  );
}
