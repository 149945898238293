import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { IonApp } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import "../theme/variables.css";
import "../styles/main.css";
// import { getCoreScreens } from "../config/navConfig";
import Meeting from "../pages/Meeting/index";
import Profile from "../pages/Profile/index";

import { RootState, useTypedSelector } from "./rootReducer";
import history from "../utils/history";
import Login from "../pages/Login";
import ProtectedRoute from "../components/ProtectedRoute";
import { getCoreScreens } from "../config/navConfig";
import NotFound from "../components/NotFound";
import FullScreenLoader from "../components/FullScreenLoader";

const App: React.FC = () => {
  // Apollo Setup
  const authState = useTypedSelector((state: RootState) => state.auth);
  const appState = useTypedSelector((state: RootState) => state.app);

  const isSignedInWithProfile =
    authState.status === "in" && authState.retrievedProfile;

  const role = useTypedSelector((state: RootState) => state.auth.profile.role);
  console.log("Role Context:", role);

  useEffect(() => {
    console.log(process.env.NODE_ENV);
  }, []);
  if (!appState.appInitialized) return <FullScreenLoader />;

  return (
    <>
      <IonApp style={{ width: "100vw", height: "100vh" }}>
        {/* <Offline>
          You are currently offline. Catalyst requires an active
          internet connection in order to operate.
        </Offline> */}

        <Router history={history}>
          <Switch>
            <Route path="/login" component={Login} exact />
            <Route path="/login" component={Login} exact />

            {getCoreScreens(role).map((navItem) => (
              <ProtectedRoute
                key={navItem.path}
                loggedIn={isSignedInWithProfile}
                path={navItem.path}
                component={navItem.component}
                exact
              />
            ))}

            {/* {getCoreScreens(role).map((navItem) => {
          if (navItem.type === "menu")
            return (
              <Popover className="relative">
                <Popover.Button>Test</Popover.Button>
                {navItem.children.map((subNavItem: any) => (
                  <NavLink
                    key={subNavItem.path}
                    to={subNavItem.path as any}
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50 hover:text-gray-900"
                  >
                    {subNavItem.title}
                  </NavLink>
                ))}
              </Popover>
            );

          return (
            <NavLink
              key={navItem.path}
              to={navItem.path as any}
              className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50 hover:text-gray-900"
            >
              {navItem.title}
            </NavLink>
          );
        })} */}

            <ProtectedRoute
              loggedIn={isSignedInWithProfile}
              path="/profiles/:id"
              component={Profile}
              exact
            />

            <Route
              // loggedIn={isSignedInWithProfile}
              path="/meeting/:roomName"
              component={Meeting}
              exact
            />
            <Route path="/" exact render={() => <Redirect to="/home" />} />

            {isSignedInWithProfile ? (
              <Route render={() => <NotFound />} />
            ) : (
              <Route render={() => <Redirect to="/login" />} />
            )}
          </Switch>
        </Router>
      </IonApp>
    </>
  );
};

export default App;
