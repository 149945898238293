import { ChakraProvider, Spinner } from "@chakra-ui/react";
import React from "react";
import { Toaster } from "react-hot-toast";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import store, { persistor } from "./store";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../config/stripe";
import AppAuthentication from "./AppAuthentication";

const promise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Spinner size="xl" />} persistor={persistor}>
        <AppAuthentication />

        <Elements stripe={promise}>
          <div>
            <Toaster />
          </div>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </Elements>
      </PersistGate>
    </Provider>
  );
};

export default AppWrapper;
