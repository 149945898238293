import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Logo } from "../../components/Logo";
import MainLayout from "../../components/MainLayout";
import { DEFAULT_PROFILE_PICTURE_URL } from "../../config/images";
import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  GlobeIcon,
  SearchIcon,
} from "@heroicons/react/solid";
import { auth } from "../../App/AppAuthentication";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import historyInstance from "../../utils/history";
import { useTypedSelector } from "../../App/rootReducer";
import RoomTable from "../../components/RoomTable";

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const [roomData, setRoomData] = useState<any>();
  const [meetingParticipants, setMeetingParticipants] = useState<any[]>();
  const { displayName, profilePictureUrl, isProAccount } = useTypedSelector(
    (state) => state.auth.profile
  );

  const firebaseUid = useTypedSelector((state) => state.auth.firebaseUid);
  const [monthlyActiveUsersCount, setMonthlyActiveUsersCount] =
    useState<number>();

  useEffect(() => {
    axiosInstance
      .get(`/livekit/rooms/me/list`)
      .then((res) => {
        console.log(res.data);
        setRoomData(res.data);
      })
      .catch((e) => {
        toast.error("There was an issue retrieving the live room data.");
        console.log(e);
      });

    axiosInstance
      .get(`/meeting-participants/unique`)
      .then((res) => {
        console.log("Meeting Participant Data", res.data);
        setMeetingParticipants(res.data.meetingParticipants);
        setMonthlyActiveUsersCount(res.data.monthlyActiveUsersCount);
      })
      .catch((e) => {
        toast.error(
          "There was an issue retrieving the meeting participant data."
        );
        console.log(e);
      });
  }, []);

  const userCountToCost = (userCount: number) => {
    if (userCount < 30) return 0;

    let cost = userCount * 0.27;

    // Round to two decimal places
    cost = Math.round(cost * 100) / 100;

    return cost;
  };

  const cards = [
    {
      name: "Active Rooms",
      href: "#",
      icon: HomeIcon,
      amount: roomData?.length,
    },
    {
      name: "Monthly Active Users",
      href: "#",
      icon: UserGroupIcon,
      amount: monthlyActiveUsersCount,
    },
    {
      name: "Estimated Cost",
      href: "#",
      icon: CashIcon,
      amount: "$" + userCountToCost(monthlyActiveUsersCount || 0),
    },

    // More items...
  ];

  return (
    <MainLayout>
      {/* Page header */}
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="flex-1 min-w-0">
              {/* Profile */}
              <div className="flex items-center">
                <img
                  className="hidden w-16 h-16 rounded-full sm:block"
                  src={profilePictureUrl || DEFAULT_PROFILE_PICTURE_URL}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="w-16 h-16 rounded-full sm:hidden"
                      src={profilePictureUrl || DEFAULT_PROFILE_PICTURE_URL}
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      Welcome back, {displayName}
                    </h1>
                  </div>
                  <dl className="flex flex-col mt-6 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    {/* <dd className="flex items-center text-sm font-medium text-gray-500 capitalize sm:mr-6">
                      <OfficeBuildingIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Catalyst
                    </dd> */}
                    <dt className="sr-only">Account status</dt>
                    <dd className="flex items-center mt-3 text-sm font-medium text-gray-500 capitalize sm:mr-6 sm:mt-0">
                      <CheckCircleIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-purple-400"
                        aria-hidden="true"
                      />
                      {isProAccount ? "Pro Account" : "Free Account"}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="flex mt-6 space-x-3 md:mt-0 md:ml-4">
              <button
                type="button"
                onClick={() => historyInstance.push("/api-keys")}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              >
                View API Keys
              </button>
              <button
                type="button"
                onClick={() => historyInstance.push("/api-keys")}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              >
                Get Started
              </button>
              {/* <button
								type="button"
								onClick={() => toast("Playground is coming soon")}
								className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
							>
								Open Playground
							</button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Statistics
          </h2>
          <h5 className="text-sm leading-6 text-gray-600 font-small">
            This month
          </h5>
          <div className="grid grid-cols-1 gap-5 mt-2 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {cards.map((card) => (
              <div
                key={card.name}
                className="overflow-hidden bg-white rounded-lg shadow"
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon
                        className="w-6 h-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="flex-1 w-0 ml-5">
                      <dl>
                        <dt className="text-sm font-medium text-gray-500 truncate">
                          {card.name}
                        </dt>
                        <dd>
                          <div className="text-lg font-medium text-gray-900">
                            {card.amount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                {/* <div className="px-5 py-3 bg-gray-50">
                  <div className="text-sm">
                    <a
                      href={card.href}
                      className="font-medium text-light-blue-700 hover:text-light-blue-900"
                    >
                      View all
                    </a>
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </div>

        <h2 className="max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
          Live Rooms
        </h2>

        {/* Activity list (smallest breakpoint only) */}
        <div className="shadow sm:hidden">
          <ul className="mt-2 overflow-hidden divide-y divide-gray-200 shadow sm:hidden">
            {roomData?.map((room: any) => (
              <li key={room.sid}>
                <a
                  href={room.sid}
                  className="block px-4 py-4 bg-white hover:bg-gray-50"
                >
                  <span className="flex items-center space-x-4">
                    <span className="flex flex-1 space-x-2 truncate">
                      <CashIcon
                        className="flex-shrink-0 w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="flex flex-col text-sm text-gray-500 truncate">
                        <span className="truncate">
                          {room.name}: {room.sid}
                        </span>
                        <span>
                          <span className="font-medium text-gray-900">
                            {room.maxParticipants}
                          </span>{" "}
                          Success
                        </span>
                        <time dateTime={room.creationDate}>
                          {room.creationDate}
                        </time>
                      </span>
                    </span>
                    <ChevronRightIcon
                      className="flex-shrink-0 w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </li>
            ))}
          </ul>

          <nav
            className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex justify-between flex-1">
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500"
              >
                Previous
              </a>
              <a
                href="#"
                className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500"
              >
                Next
              </a>
            </div>
          </nav>
        </div>

        {/* Activity table (small breakpoint and up) */}
        <RoomTable roomData={roomData} />
      </div>
    </MainLayout>
  );
}
