import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {
  component: any;
  loggedIn: boolean;
}

const ProtectedRoute = ({
  component: Comp,
  loggedIn,
  path,
  ...rest
}: ProtectedRouteProps) => {
  // TODO: Implement role based protected routes?

  useEffect(() => {
    if (!loggedIn) {
      toast.error("You cannot view this page without signing in. ");
    }
  }, [loggedIn]);

  return (
    // <>
    //   {loggedIn ? (
    //     <Route path={path} component={Comp} />
    //   ) : (
    //     <Redirect to="/login" />
    //   )}
    // </>
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
