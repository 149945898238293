import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import axiosInstance from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setProStatus } from "../Login/authSlice";
import AddPaymentMethodModal from "./AddPaymentMethodModal";

export default function SwitchToProAccountButton() {
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onPaymentMethodModalOpen,
    onClose: onPaymentMethodModalClose,
  } = useDisclosure();

  const handleSwitchToProAccount = async () => {
    try {
      await axiosInstance.put("/users/me/switch-to-pro");
      toast.success("Successfully switched to a pro account.");
      dispatch(setProStatus(true));
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong while trying to switch to a pro account. Please try again."
      );
      toast.error(error?.response?.data?.message);

      if (error?.response?.data?.type === "no_valid_payment_method") {
        onClose();
        onPaymentMethodModalOpen();
      }
    }
  };
  return (
    <>
      <AddPaymentMethodModal
        isOpen={isPaymentMethodModalOpen}
        onClose={onPaymentMethodModalClose}
      />
      <button
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
        onClick={onOpen}
      >
        Switch to Pro Account
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Switch to a pro account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Upon switching to a pro account, your 30 user cap will disappear.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSwitchToProAccount}
            >
              Switch to Pro
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
