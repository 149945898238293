import React, { useEffect } from "react";
import OverlapLayout from "../../components/OverlapLayout";
import { useParams } from "react-router-dom";
import { useState } from "react";

import { MailIcon } from "@heroicons/react/solid";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-hot-toast";
import { User } from "../../types/users";

const tabs = [
  { name: "Profile", href: "#", current: true },
  { name: "Portfolio", href: "#", current: false },
  { name: "Workshops", href: "#", current: false },
];
const profile = {
  name: "Ricardo Cooper",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  coverImageUrl:
    "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  about:
    "\n        <p>\n          Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.\n        </p>\n        <p>\n          Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.\n        </p>\n      ",
  fields: {
    Phone: "(555) 123-4567",
    Email: "ricardocooper@example.com",
    Title: "Senior Front-End Developer",
    Team: "Product Development",
    Location: "San Francisco",
    Sits: "Oasis, 4th floor",
    Salary: "$145,000",
    Birthday: "June 8, 1990",
  },
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const { id } = useParams<{ id: string }>();
  const [loadingProfileData, setLoadingProfileData] = useState(false);
  const [userData, setUserData] = useState<User>();

  // Fetch data and populate form fields for editing
  useEffect(() => {
    setLoadingProfileData(true);

    if (!id) return console.log("Awaiting ID in URL");

    axiosInstance
      .get(`/users/${id}`)
      .then((res) => {
        const responseUser = res.data;
        setUserData(responseUser);
        setLoadingProfileData(false);
      })
      .catch((e) => {
        toast.error("There was an issue retrieving the user's profile.");
        console.log(e);
      });
  }, [id]);

  const profileFields = {
    Email: userData?.email,
    University: userData?.university,
  };

  return (
    <OverlapLayout title="View Profile" className="min-h-screen bg-gray-100">
      <div className="flex bg-white rounded-xl">
        {/* Static sidebar for desktop */}
        <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                {/* Profile header */}
                <div>
                  <div>
                    <img
                      className="object-cover w-full h-32 lg:h-48 rounded-t-xl"
                      src={profile.coverImageUrl}
                      alt=""
                    />
                  </div>
                  <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                      <div className="flex">
                        <img
                          className="w-24 h-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                          src={userData?.profilePictureUrl}
                          alt=""
                        />
                      </div>
                      <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
                          <h1 className="text-2xl font-bold text-gray-900 truncate">
                            {`${userData?.firstName} ${userData?.lastName}`}
                          </h1>
                        </div>
                        <div className="flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                          >
                            <MailIcon
                              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Message</span>
                          </button>
                          {/* <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                          >
                            <PhoneIcon
                              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Call</span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
                      <h1 className="text-2xl font-bold text-gray-900 truncate">
                        {`${userData?.firstName} ${userData?.lastName}`}
                      </h1>
                    </div>
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                      <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? "border-pink-500 text-gray-900"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* Description list */}
                <div className="max-w-5xl px-4 mx-auto mt-6 sm:px-6 lg:px-8">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {Object.keys(profileFields).map((field) => (
                      <div key={field} className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {field}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {profileFields[field as keyof typeof profileFields]}
                        </dd>
                      </div>
                    ))}
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        About
                      </dt>
                      <dd className="mt-1 space-y-5 text-sm text-gray-900 max-w-prose">
                        {userData?.biography
                          ? userData?.biography
                          : "No biography written."}
                      </dd>
                    </div>
                  </dl>
                </div>

                {/* Workshop list */}
                <div className="max-w-5xl px-4 pb-12 mx-auto mt-8 sm:px-6 lg:px-8">
                  <h2 className="text-sm font-medium text-gray-500">
                    Workshops
                  </h2>
                  <div className="grid grid-cols-1 gap-4 mt-1 sm:grid-cols-2">
                    {userData?.workshops.map((workshop) => (
                      <div
                        key={workshop.title}
                        className="relative flex items-center px-6 py-5 space-x-3 bg-white border border-gray-300 rounded-lg shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="w-10 h-10 rounded-full"
                            src={workshop.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <a href="#" className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              {workshop.title}
                            </p>
                            <p className="text-sm text-gray-500 truncate">
                              {workshop.description}
                            </p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </OverlapLayout>
  );
}
