import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import toast from "react-hot-toast";
import { useTypedSelector } from "../../App/rootReducer";
import axiosInstance from "../../utils/axiosInstance";
import { setProStatus } from "../Login/authSlice";
import { useDispatch } from "react-redux";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function AddPaymentMethodModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [processing, setProcessing] = React.useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { displayName, profilePictureUrl, isProAccount, email } =
    useTypedSelector((state) => state.auth.profile);

  const handleSubmit = async (event: any) => {
    try {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      setProcessing(true);

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        toast("Please wait a moment... Processing... Retry in a moment...");
        return;
      }

      // Get client secret from the server
      const response = await axiosInstance.get("/stripe/card-wallet");
      const clientSecret = response.data.client_secret;

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement) as any,
          billing_details: {
            name: displayName || "No valid name",
            email: email || "No valid email",
          },
        },
      });

      if (result.error) {
        // Display result.error.message in your UI.
        toast.error(JSON.stringify(result.error));
      } else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        const response = await axiosInstance.post(
          "/stripe/add-payment-method",
          {
            paymentMethodId: result.setupIntent.payment_method,
          }
        );
        if (response?.data?.success) {
          toast.success("Payment method added successfully");
          dispatch(setProStatus(true));
        } else {
          toast.error(
            "Something went wrong in acknowledging the new payment method."
          );
        }
      }
    } catch (error) {
      toast.error(error?.message);
      toast.error(JSON.stringify(error));
    }

    setProcessing(false);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Activate Catalyst Pro</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text className="mb-2 text-md">
              In order to activate Catalyst Pro, you'll need to add a payment
              method.
            </Text>
            <CardElement
              className="p-4 mb-2 rounded shadow"
              options={CARD_ELEMENT_OPTIONS}
            />
            <Text className="text-xs font-semibold">
              I authorise Catalyst Scientific LLC to send instructions to the
              financial institution that issued my card to take payments from my
              card account in accordance with the terms of my agreement with
              you.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSubmit}
              disabled={processing || !stripe}
              loading={processing}
            >
              {processing ? <Spinner /> : "Activate"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
