import React, { Fragment, useState } from "react";

import { Listbox } from "@headlessui/react";
import {
  ChevronRightIcon,
  BriefcaseIcon,
  LocationMarkerIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  PencilIcon,
  LinkIcon,
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import MainLayout from "../../components/MainLayout";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/tailwind";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
const publishingOptions = [
  {
    name: "Live",
    description: "Webhook responses will be forwarded to your specified URL.",
    current: true,
  },
  {
    name: "Test",
    description:
      "Webhook responses will be displayed in the management dashboard.",
    current: true,
  },
];
export default function Webhooks() {
  const [selected, setSelected] = useState(publishingOptions[0]);

  const handleWebhookCreate = () => toast("Webhook creation coming soon...");
  return (
    <MainLayout>
      <div className="h-full">
        <header className="py-8 bg-gray-50">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4" role="list">
                  <li>
                    <div>
                      <Link
                        to="/home"
                        className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Management
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Link
                        to="/webhooks"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Webhooks
                      </Link>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Manage Webhooks
              </h1>
              {/* <div className="flex flex-col mt-1 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <BriefcaseIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Full-time
                </div>
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <LocationMarkerIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Remote
                </div>
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <CurrencyDollarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  $120k &ndash; $140k
                </div>
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Closing on January 9, 2020
                </div>
              </div> */}
            </div>
            <div className="flex mt-5 xl:mt-0 xl:ml-4">
              <span className="hidden sm:block">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-light-blue-500"
                >
                  <PencilIcon
                    className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                    aria-hidden="true"
                  />
                  Edit
                </button>
              </span>

              <span className="hidden ml-3 sm:block">
                <button
                  type="button"
                  onClick={handleWebhookCreate}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-light-blue-500"
                >
                  <PlusIcon
                    className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                    aria-hidden="true"
                  />
                  Create Webhook
                </button>
              </span>

              <span className="relative z-0 sm:ml-3">
                <Listbox value={selected} onChange={setSelected}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only">
                        Change published status
                      </Listbox.Label>
                      <div className="relative">
                        <div className="inline-flex divide-x divide-light-blue-600 rounded-md shadow-sm">
                          <div className="relative z-0 inline-flex divide-x divide-light-blue-600 rounded-md shadow-sm">
                            <div className="relative inline-flex items-center py-2 pl-3 pr-4 text-white bg-light-blue-500 border border-transparent shadow-sm rounded-l-md">
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2.5 text-sm font-medium">
                                {selected.name}
                              </p>
                            </div>
                            <Listbox.Button className="relative inline-flex items-center p-2 text-sm font-medium text-white bg-light-blue-500 rounded-l-none rounded-r-md hover:bg-light-blue-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-light-blue-500">
                              <span className="sr-only">
                                Change published status
                              </span>
                              <ChevronDownIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            </Listbox.Button>
                          </div>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute left-0 mt-2 -mr-1 overflow-hidden origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0"
                          >
                            {publishingOptions.map((option) => (
                              <Listbox.Option
                                key={option.name}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-light-blue-500"
                                      : "text-gray-900",
                                    "cursor-default select-none relative p-4 text-sm"
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <div className="flex flex-col">
                                    <div className="flex justify-between">
                                      <p
                                        className={
                                          selected
                                            ? "font-semibold"
                                            : "font-normal"
                                        }
                                      >
                                        {option.name}
                                      </p>
                                      {selected ? (
                                        <span
                                          className={
                                            active
                                              ? "text-white"
                                              : "text-light-blue-500"
                                          }
                                        >
                                          <CheckIcon
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </div>
                                    <p
                                      className={classNames(
                                        active
                                          ? "text-light-blue-200"
                                          : "text-gray-500",
                                        "mt-2"
                                      )}
                                    >
                                      {option.description}
                                    </p>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </span>

              {/* Dropdown */}
              <Menu as="span" className="relative ml-3 sm:hidden">
                {({ open }) => (
                  <>
                    <Menu.Button className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500">
                      More
                      <ChevronDownIcon
                        className="w-5 h-5 ml-2 -mr-1 text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 w-48 py-1 mt-2 -mr-1 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Edit
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              View
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </header>

        <main
          className="p-6 m-4 bg-white shadow rounded-xl"
          onClick={handleWebhookCreate}
        >
          <div className="text-center">
            <svg
              className="w-12 h-12 mx-auto text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No webhooks
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Extend the functionality of Catalyst by adding custom webhooks.
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-light-blue-600 border border-transparent rounded-md shadow-sm hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              >
                <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
                New Webhook
              </button>
            </div>
          </div>
        </main>
      </div>
    </MainLayout>
  );
}
