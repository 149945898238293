import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";

// Firebase config
import firebase from "firebase/app";
import {
  signedIn,
  signedOut,
  updateToken,
  setProfileData,
} from "../pages/Login/authSlice";
import axiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import { useTypedSelector } from "./rootReducer";
import { setAppInitialized } from "./appSlice";
import { productionFirebase, developmentFirebase } from "../config/firebase";
require("firebase/auth");
require("firebase/firestore");

// Firebase configuration provided to us by the app creation process
export const firebaseConfig =
  process.env.NODE_ENV === "production"
    ? productionFirebase
    : developmentFirebase;
// Initializes Firebase and creates an app instance

try {
  !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();
} catch (err) {
  // Catches 'already initialized' errors and logs it to enable hot reloading to continue to work
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error raised", err.stack);
  }
}

export const auth = firebase.auth();
export const db = firebase.firestore();

const AppAuthentication: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const authToken = useTypedSelector((state) => state.auth.token);

  // Set up Axios with auth key from persist if it exists
  if (authToken) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${authToken}`;
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const firebaseUid = user.uid;
        const token = await user.getIdToken();
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        dispatch(signedIn({ status: "in", token, firebaseUid }));

        // Update profile data
        try {
          const { data: resData } = await axiosInstance.get("/session/profile");

          console.log(resData);
          dispatch(setProfileData(resData));
        } catch (e) {
          toast.error(e?.message);
        }

        // Initialize app after authenticating and getting profile
        dispatch(setAppInitialized(true));
      } else {
        // Clear data instantly after signing out (or if signed out)
        dispatch(signedOut());
        // Initialize app in signed out state
        dispatch(setAppInitialized(true));
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        // User is signed in or token was refreshed.

        const token = await user.getIdToken();
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        dispatch(updateToken(token));
      }
    });
    return unsubscribe;
  }, [dispatch]);

  return null;
};

export default AppAuthentication;
