import { chakra, HTMLChakraProps } from "@chakra-ui/react";
import * as React from "react";
import color_logo_only from "../images/color_logo_only.png";
import white_logo_only from "../images/white_logo_only.png";
import black_logo_only from "../images/black_logo_only.png";
import color_wordmark from "../images/color_wordmark.png";
import white_wordmark from "../images/white_wordmark.png";
import black_wordmark from "../images/black_wordmark.png";

const logoTypes = {
  color_logo_only,
  white_logo_only,
  black_logo_only,
  color_wordmark,
  white_wordmark,
  black_wordmark,
};
interface LogoProps extends HTMLChakraProps<"img"> {
  logoType?: keyof typeof logoTypes;
}

export const Logo = (props: LogoProps) => {
  // const color = useToken("colors", iconColor);

  return (
    <chakra.img
      src={logoTypes[props.logoType || "color_logo_only"]}
      width="50px"
      height="50px"
      {...props}
    />
  );
};
