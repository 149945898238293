import React, { useState, useEffect } from "react";
import { Jutsu } from "react-jutsu";
import { Link, useParams } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Spinner } from "@chakra-ui/react";
import axiosInstance from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import videoChatAnimation from "../../images/lotties/video_chat.json";
import { useTypedSelector } from "../../App/rootReducer";
import historyInstance from "../../utils/history";

export default function Meeting() {
  // const [room, setRoom] = useState("");
  // const [name, setName] = useState("");
  // const [call, setCall] = useState(false);
  // const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [durationPast, setDurationPast] = useState(false);

  const { roomName } = useParams<{ roomName: string }>();

  // const handleClick = (event: any) => {
  //   event.preventDefault();
  //   if (room && name) setCall(true);
  // };
  const authState = useTypedSelector((state) => state.auth);

  const isSignedInWithProfile =
    authState.status === "in" && authState.retrievedProfile;

  const jitsiTokenRequestUrl = isSignedInWithProfile
    ? "/jitsi/token"
    : "/jitsi/guest-token";

  useEffect(() => {
    axiosInstance
      .get(jitsiTokenRequestUrl)
      .then((res) => setToken(res.data.token))
      .catch((e) => toast.error(e.message));
  }, [jitsiTokenRequestUrl]);

  useEffect(() => {
    setTimeout(() => setDurationPast(true), 5500);
  });

  // useEffect();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: videoChatAnimation,
  };

  return token && durationPast ? (
		<>
			<Jutsu
				containerStyles={{ width: "100%", height: "90vh" }}
				roomName={`vpaas-magic-cookie-f2a33b3909b6444689fcde4237b88e69/${roomName}`}
				jwt={token}
				domain="8x8.vc"
				onMeetingEnd={() => historyInstance.push("/")}
				onJitsi={(jitsi: any) => console.log(jitsi)}
				loadingComponent={<p>loading ...</p>}
				errorComponent={<p>Oops, something went wrong</p>}
			/>
			<div className="fixed inset-x-0 bottom-0 p-4">
				<section
					style={{ height: "7vh" }}
					className="relative py-3 pl-3 pr-12 text-left rounded-lg shadow-xl sm:text-center bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
				>
					<p className="text-sm font-medium text-white">
						You are currently in room: {roomName}
						<Link
							to="/home"
							className="inline-flex items-center ml-2 underline"
						>
							Go home
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 ml-1.5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</Link>
					</p>
				</section>
			</div>
		</>
	) : (
		<div className="relative flex items-center min-h-screen p-5 overflow-hidden bg-rose-100 min-w-screen lg:p-20">
			<div className="relative items-center flex-1 min-w-full min-h-full p-10 text-center text-gray-800 bg-white shadow-xl rounded-3xl lg:p-20 md:flex md:text-left">
				<div className="w-full md:w-1/2">
					<div className="mb-10 lg:mb-20">
						<Logo />
					</div>
					<div className="mb-10 font-light text-gray-600 md:mb-20">
						<h1 className="mb-10 text-3xl font-black text-rose-500 lg:text-5xl">
							<Spinner size="xl" color="red" mr="4" />
							Joining meeting...
						</h1>
						<p className="font-bold">
							Please wait while we authenticate you for your meeting.
						</p>
						<p>
							If this process takes more than a few minutes. Please refresh the
							page.
						</p>
					</div>
					<div className="mb-20 md:mb-0">
						<Link
							className="inline-block p-1 rounded-lg bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
							to={"/home"}
						>
							<span className="block px-5 py-3 font-medium text-white bg-gray-900 rounded-md hover:bg-opacity-75">
								Go Home
							</span>
						</Link>
					</div>
				</div>
				<div className="w-full text-center md:w-1/2">
					<Lottie options={lottieOptions} height={400} width={400} />
				</div>
			</div>
			<div className="absolute w-64 transform -rotate-45 bg-red-200 rounded-full pointer-events-none md:w-96 h-96 md:h-full bg-opacity-30 -top-64 md:-top-96 right-20 md:right-32" />
			<div className="absolute h-full transform -rotate-45 bg-red-200 rounded-full pointer-events-none w-96 bg-opacity-20 -bottom-96 right-64" />
		</div>
	);
}
