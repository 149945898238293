import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import axiosInstance from "../../utils/axiosInstance";
import { toast } from "react-hot-toast";
import { ParticipantInfo } from "../../types/livekit";

export default function ParticipantModalButton({
  roomName,
}: {
  roomName: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [participantData, setParticipantData] = useState<ParticipantInfo[]>();

  const updateData = () =>
    axiosInstance
      .get(`/livekit/rooms/${roomName}/participants`)
      .then((res) => {
        console.log(res.data);
        setParticipantData(res.data);
      })
      .catch((e) => {
        toast.error("There was an issue retrieving the participant data.");
        console.log(e);
      });

  useEffect(() => {
    axiosInstance
      .get(`/livekit/rooms/${roomName}/participants`)
      .then((res) => {
        console.log(res.data);
        setParticipantData(res.data);
      })
      .catch((e) => {
        toast.error("There was an issue retrieving the participant data.");
        console.log(e);
      });
  }, [roomName]);

  const handleCloseRoom = () => {
    axiosInstance
      .delete(`/livekit/rooms/${roomName}`)
      .then((res) => {
        toast.success("Room closed successfully.");
        console.log(res);
      })
      .catch((e) => {
        toast.error("There was an issue closing the room.");
        console.log(e);
      });
  };

  const handleRemoveParticipant = (identity: string | undefined) => {
    if (!identity) {
      return;
    }
    axiosInstance
      .delete(`/livekit/rooms/${roomName}/participants/${identity}`)
      .then((res) => {
        toast.success("Participant removed successfully.");
        console.log("Deleted participant response", res);
        updateData();
      })
      .catch((e) => {
        toast.error("There was an issue removing the participant.");
        console.log(e);
      });
  };

  return (
    <>
      <button
        type="button"
        onClick={onOpen}
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Participants & Actions
      </button>

      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{roomName} Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <table className="table w-full space-y-6 text-sm text-gray-400 border-separate">
              <thead className="p-4 text-gray-500 bg-gray-100">
                <tr className="p-4">
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Identity
                  </th>
                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Hidden
                  </th>

                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Joined At
                  </th>

                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Metadata
                  </th>

                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    SID
                  </th>

                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    State
                  </th>

                  <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {participantData?.map((participant) => {
                  return (
                    <tr key={participant.identity}>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        {participant?.identity}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        {participant?.hidden}
                      </td>

                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 whitespace-nowrap">
                        {participant?.joinedAt}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0">
                        {participant?.metadata}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 ">
                        {participant?.sid}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 ">
                        {participant?.state}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900 max-w-0 ">
                        <Button
                          colorScheme="red"
                          size="xs"
                          onClick={() =>
                            handleRemoveParticipant(participant?.identity)
                          }
                        >
                          Remove Participant
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseRoom}>
              Close Room
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
